'use strict';

import $ from 'jquery';
import 'gsap';

import EventBus from './utils/EventBus';

export default class StillThereHint {
    constructor() {
        this.$el = $('#still-there');
        this.timeout = undefined;
        this.paused = false;

        this.bindEvents();
        this.startTimeout();
    }

    bindEvents() {
        $('body').on('mousedown touchstart', () => {
            this.hide();
            this.stopTimeout();
            this.startTimeout();
        });

        EventBus.subscribe('attractor/show', () => {
            this.hide();
            this.stopTimeout();
            this.paused = true;
        });

        EventBus.subscribe('attractor/hide', () => {
            this.startTimeout();
            this.paused = false;
        });

        EventBus.subscribe('object-display/show-overlay', () => {
            this.stopTimeout();
            this.paused = true;
        });

        EventBus.subscribe('object-display/hide-overlay', () => {
            this.stopTimeout();
            this.startTimeout();
            this.paused = false;
        });
    }

    startTimeout() {
        this.timeout = window.setTimeout(() => {
            this.show();
        }, options.stillThereHintTimeout);
    }

    stopTimeout() {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }

    hide() {
        TweenMax.to(this.$el, 0.5, {
            alpha: 0,
            ease: Sine.easeOut,
            display: 'none'
        });

        EventBus.publish('still-there-hint/hide');
    }

    show() {
        if (this.paused) return;

        TweenMax.to(this.$el, 1, {
            alpha: 1,
            ease: Sine.easeIn,
            display: 'block'
        });

        EventBus.publish('still-there-hint/show');
    }
}
