'use strict';

import $ from 'jquery';
import 'gsap';

import EventBus from './utils/EventBus';
import Debug from './utils/Debug';


/**
 * Side menu
 */
export default class SideMenu {
    constructor() {
        Debug.log(this, "initialised")();

        this.$domEl = $(".side-menu");
        this.$mapHarness = $(".map-harness", this.$domEl);
        this.$objectsHarness = $(".objects-harness", this.$domEl);
        this.$mapButtons = $("button", this.$mapHarness);
        this.$objectsButtons = $("button", this.$objectsHarness);

        this.reset();
        this.show();
        this.bindEvents();
    }

    bindEvents() {
        this.$mapButtons.on("mousedown", event => {
            this.highlightMapButton($(event.target));
            EventBus.publish('sidemenu/change-map-layer', $(event.target).attr('data-id'));
        });

        this.$objectsButtons.on("mousedown", event => {
            this.highlightObjectFilterButtons($(event.target));
        });

        EventBus.subscribe('attractor/show', this.reset.bind(this));
    }

    highlightMapButton($button) {
        if ($button.hasClass("selected")) return;

        // select
        this.$mapButtons.removeClass("selected");
        TweenMax.to($button, .15, {className: "+=selected", ease:Sine.easeIn});

        // change copy
        this.resetMapButtonsText();
        $button.text( $button.attr("data-selected") );
    }

    highlightObjectFilterButtons($button) {
        if ($button.attr("data-id") == "select_all") {
            // select all
            TweenMax.to(this.$objectsButtons.not($button), .15, {className: "+=selected", ease:Sine.easeIn, onComplete: updateSelectedEras});
        } else {
            // select
            var classChange = $button.hasClass("selected") ? "-=selected" : "+=selected";
            TweenMax.to($button, .15, {className: classChange, ease:Sine.easeIn, onComplete: updateSelectedEras});
        }

        function updateSelectedEras() {
            let selectedEras = [];
            $('.objects-harness button.era').each((index, button) => {
                if ($(button).hasClass('selected')) {
                    selectedEras.push($(button).attr('data-id'));
                }
            });

            EventBus.publish('sidemenu/filter-objects', { selectedEras });
        }
    }

    show() {
        TweenMax.set(this.$mapHarness, {x:-340});
        TweenMax.set(this.$mapHarness.find(".heading"), {autoAlpha:1});
        TweenMax.set(this.$mapHarness.find("button"), {y:-20, scaleY:0, transformOrigin:"0 0"});

        TweenMax.set(this.$objectsHarness, {x:-340});
        TweenMax.set(this.$objectsHarness.find(".heading"), {autoAlpha:1});
        TweenMax.set(this.$objectsHarness.find("button"), {y:-20, scaleY:0, transformOrigin:"0 0"});

        let tl = new TimelineMax({ delay:.4 });

        // bring map group on with buttons hidden, then stagger show buttons
        tl.to(this.$mapHarness, .2, {x:0, ease:Sine.easeOut});
        tl.staggerTo(this.$mapHarness.find("button"), .5, {y:0, scaleY:1, autoAlpha:1, ease:Expo.easeOut}, .06);

        // same with objects group
        tl.to(this.$objectsHarness, .2, {delay: -.3, x:0, ease:Sine.easeOut});
        tl.staggerTo(this.$objectsHarness.find("button"), .45, {y:0, scaleY:1, autoAlpha:1, ease:Expo.easeOut}, .06);
    }

    resetMapButtonsText() {
        this.$mapButtons.each(function(i, html){
            $(html).text( $(html).attr("data-default") );
        }.bind(this));
    }

    reset() {
        this.resetMapButtonsText();
        this.highlightMapButton($("button[data-id='presentday']"));
        this.highlightObjectFilterButtons($("button[data-id='select_all']"));
    }
}
