module.exports={
  "id": "afterRequest.json#",
  "type": "object",
  "optional": true,
  "required": [
    "lastAccess",
    "eTag",
    "hitCount"
  ],
  "properties": {
    "expires": {
      "type": "string",
      "pattern": "^(\\d{4})(-)?(\\d\\d)(-)?(\\d\\d)(T)?(\\d\\d)(:)?(\\d\\d)(:)?(\\d\\d)(\\.\\d+)?(Z|([+-])(\\d\\d)(:)?(\\d\\d))?"
    },
    "lastAccess": {
      "type": "string",
      "pattern": "^(\\d{4})(-)?(\\d\\d)(-)?(\\d\\d)(T)?(\\d\\d)(:)?(\\d\\d)(:)?(\\d\\d)(\\.\\d+)?(Z|([+-])(\\d\\d)(:)?(\\d\\d))?"
    },
    "eTag": {
      "type": "string"
    },
    "hitCount": {
      "type": "integer"
    },
    "comment": {
      "type": "string"
    }
  }
}
