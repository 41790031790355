'use strict';

import $ from 'jquery';
import 'gsap';

import EventBus from './utils/EventBus';

export default class Attractor {
    constructor() {
        this.$el = $('#attractor');
        this.$el.show();
        this.timeout = undefined;
        this.paused = false;

        this.bindEvents();
    }

    bindEvents() {
        this.$el.on('click', this.hide.bind(this));

        $('body').on('mousedown touchstart', () => {
            if (! this.paused) {
                this.stopTimeout();
                this.startTimeout();
            }
        });

        EventBus.subscribe('object-display/show-overlay', data => {
            if (data.type === 'video') {
                this.stopTimeout();
                this.paused = true;
            }
        });

        EventBus.subscribe('object-display/hide-overlay', () => {
            this.stopTimeout();
            this.startTimeout();
            this.paused = false;
        });
    }

    startTimeout() {
        this.timeout = window.setTimeout(() => {
            this.show();
        }, options.attractorTimeout);
    }

    stopTimeout() {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }

    hide() {
        this.$el[0].pause();
        TweenMax.to(this.$el, 0.15, {
            autoAlpha: 0,
            ease: Sine.easeOut,
            display: 'none'
        });

        EventBus.publish('attractor/hide');
    }

    show() {
        this.$el[0].play();
        TweenMax.to(this.$el, 1, {
            autoAlpha: 1,
            ease: Sine.easeIn,
            display: 'block'
        });

        this.stopTimeout();

        EventBus.publish('attractor/show');
    }
}
