module.exports={
  "id": "query.json#",
  "type": "object",
  "required": [
    "name",
    "value"
  ],
  "properties": {
    "name": {
      "type": "string"
    },
    "value": {
      "type": "string"
    },
    "comment": {
      "type": "string"
    }
  }
}
