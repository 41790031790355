'use strict';

import $ from 'jquery';
import EventBus from './utils/EventBus';

export default class ObjectApi {
    constructor() {
        this.isFirstFetch = true;
        this.previousResponse = false;

        this.fetchObjectsFromApi();
        this.poll();
    }

    poll() {
        setTimeout(() => {
            this.fetchObjectsFromApi();
            this.poll();
        }, options.apiPollInterval);
    }

    fetchObjectsFromApi() {
        $.get(options.cmsUrl + '/api/v1/cache/objects.json', {}, response => {
            if (this.isFirstFetch) {
                EventBus.publish('api/load', response);
                this.isFirstFetch = false;
            }

            if (this.hasChanged(response, this.previousResponse)) {
                EventBus.publish('api/reload', response);
            }

            this.previousResponse = response;
        });
    }

    hasChanged(a, b) {
        return a.checksum !== b.checksum;
    }
}
