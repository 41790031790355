module.exports={
  "id": "har.json#",
  "type": "object",
  "required": [
    "log"
  ],
  "properties": {
    "log": {
      "$ref": "log.json#"
    }
  }
}
