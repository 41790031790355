'use strict';

// `mapboxgl` available on window object, imported in index.html
import $ from 'jquery';
import intersection from 'lodash.intersection';

import EventBus from './utils/EventBus';
import Debug from './utils/Debug';

/**
 * WebGL based map
 */
export default class Map {
    constructor() {
        Debug.log(this, 'initialised')();

        this.eras = [
            'era-1700-1800',
            'era-1800-1900',
            'era-1900-2000',
            'era-2000'
        ];

        this.layers = ['1795', '1855', '1951'];

        this.$copyrightNotice = $('#map-copyright-notice');

        this.setupMap();
        this.bindEvents();
    }

    bindEvents() {
        EventBus.subscribe('api/load', objects => {
            this.setupObjects(objects);
            this.updateMarkerSource();
            EventBus.publish('map/received-objects', objects.checksum);
        });

        EventBus.subscribe('api/reload', objects => {
            this.setupObjects(objects);
            this.updateMarkerSource();
            EventBus.publish('map/received-objects', objects.checksum);
          });

        EventBus.subscribe('sidemenu/change-map-layer', this.changeLayer.bind(this));

        EventBus.subscribe('sidemenu/filter-objects', this.filterMarkers.bind(this));

        EventBus.subscribe('objectdisplay/close', this.deselectMarker.bind(this));

        EventBus.subscribe('attractor/show', this.reset.bind(this));

        EventBus.subscribe('sidemenu/change-map-layer', this.toggleCopyrightNotice.bind(this));
    }

    setupMap() {
        this.map = new mapboxgl.Map({
            container: 'map',
            style: options.tileServerUrl + '/styles/presentday.json',
            zoom: 10.0,
            center: new mapboxgl.LngLat(-1.470085, 53.381129),
            maxBounds: new mapboxgl.LngLatBounds(
                new mapboxgl.LngLat(-1.225000, 53.507855),
                new mapboxgl.LngLat(-1.829136, 53.305237)
            )
        });
        this.map.dragRotate.disable();
        this.map.touchZoomRotate.disableRotation();
        this.map.setMinZoom(11);
        this.map.setMaxZoom(15.49);

        this.map.on('style.load', () => {
            this.setupLayers();
            this.setupMarkerSource();
            this.setupMarkerLayers();
        });

        this.map.on('click', this.selectMarker.bind(this));
    }

    setupObjects(objects) {
        let _this = this;

        let objectMethods = {
            select: function(id) {
                this.features.forEach(f => f.properties.selected = (f.properties.id === id) ? true : false);
                _this.updateMarkerSource();
            },
            deselect: function() {
                this.features.forEach(f => f.properties.selected = false);
                _this.updateMarkerSource();
            }
        };

        this.objects = Object.assign(objects, objectMethods);
    }

    setupMarkerSource() {
        if (! this.map.getSource('objects')) {
            this.map.addSource('objects', {
                type: 'geojson',
                cluster: true,
                clusterMaxZoom: 13,
                clusterRadius: 1
            });
            this.updateMarkerSource();
        }
    }

    updateMarkerSource() {
        this.map.getSource('objects').setData(this.objects);
    }

    setupMarkerLayers() {
        // Eras
        this.eras.forEach(era => {
            this.map.addLayer({
                id: era,
                type: 'symbol',
                source: 'objects',
                layout: {
                    'icon-image': 'marker',
                    'icon-offset': [0, -22]
                },
                filter: [
                    'all',
                    ['==', 'selected', false],
                    ['==', era, true]
                ]
            });
        });

        // Selected markers
        this.map.addLayer({
            id: 'markers-selected',
            type: 'symbol',
            source: 'objects',
            layout: {
                'icon-image': 'marker-selected',
                'icon-offset': [0, -37]
            },
            filter: ['==', 'selected', true]
        });
    }

    filterMarkers(data) {
        let selectedEras = data.selectedEras.map(era => 'era-' + era);

        if (typeof this.map === 'undefined') return;

        this.eras.forEach(era => {
            if (selectedEras.indexOf(era) === -1) {
                this.map.setLayoutProperty(era, 'visibility', 'none');
            } else {
                this.map.setLayoutProperty(era, 'visibility', 'visible');
            }
        });
    }

    resetMarkerFilter() {
        this.eras.forEach(era => {
            this.map.setLayoutProperty(era, 'visibility', 'visible');
        });
    }

    selectMarker(event) {
        let features = this.map.queryRenderedFeatures(event.point, {
            layers: this.eras
        });

        if (features.length) {
            // Select object
            let object = features[0];
            this.objects.select(object.properties.id);
            this.map.flyTo({ center: object.geometry.coordinates, zoom: 14 });
            EventBus.publish('marker/select', object.properties);
        } else {
            this.deselectMarker();
            EventBus.publish('marker/deselect');
        }
    }

    deselectMarker() {
        this.objects.deselect();
    }

    setupLayers() {
        this.layers.forEach(year => {
            this.map.addSource(year, {
                "type": "raster",
                "tiles": ["/tiles/" + year + "/{z}/{x}/{y}.png"],
                "tileSize": 256
            });
            this.map.addLayer({
                "id": year,
                "source": year,
                "type": "raster",
                "minzoom": 11,
                "maxzoom": 16
            });
            this.map.setLayoutProperty(year, "visibility", "none");
        });
    }

    hideLayers() {
        this.layers.forEach(year => {
            this.map.setLayoutProperty(year, 'visibility', 'none');
        });
    }

    changeLayer(layerId) {
        this.hideLayers();
        if (this.layers.indexOf(layerId) === -1) return;
        this.map.setLayoutProperty(layerId, 'visibility', 'visible');
    }

    toggleCopyrightNotice() {
        this.$copyrightNotice.hide();
        this.layers.filter(l => l === '1951' || l === '1855')
            .forEach(l => {
                if (this.map.getLayoutProperty(l, 'visibility') === 'visible') {
                    this.$copyrightNotice.show();
                }
            });
    }

    reset() {
        this.hideLayers();

        this.resetMarkerFilter();

        this.deselectMarker();

        this.map.flyTo({
            zoom: 10.0,
            center: new mapboxgl.LngLat(-1.470085, 53.381129),
            speed: 0.5
        });
    }
}
