module.exports={
  "id": "pageTimings.json#",
  "type": "object",
  "properties": {
    "onContentLoad": {
      "type": "number",
      "min": -1
    },
    "onLoad": {
      "type": "number",
      "min": -1
    },
    "comment": {
      "type": "string"
    }
  }
}
