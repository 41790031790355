'use strict';

import Debug from './Debug';

class EventBus {
    constructor() {
        Debug.log(this, "initialised")();

        this.topics = [];
    }

    subscribe(topic, listener) {
        if (! this.topics[topic]) {
            this.topics[topic] = [];
        }

        this.topics[topic].push(listener);
    }

    publish(topic, data) {
        if (! this.topics[topic] || this.topics[topic].length < 1) {
            return;
        }

        this.topics[topic].forEach(listener => {
            listener(data || {});
        });

        Debug.log(this, topic)();
    }
}

export default new EventBus();
