'use strict';

/**
 * Debug
 * Call from a class like so:
 * Debug.log(this, { boom: 'bang' }, 12345, [ 1,2,3,4,5 ], 'I AM THE KING')();
 *
 * @author Adam Palmer <adam@rhythm.digital>
 */
export default class Debug {
    /**
     * log
     * @param tag
     * @returns {function(this:*)}
     */
    static log(tag)
    {
        let name = tag;
        let css = 'background: #fff; color: #099f02;';

        if (typeof(name) !== 'string' && tag.constructor.name) {
            name = tag.constructor.name;
            if (tag.debugCSS) {
                css = tag.debugCSS;
            }
        }

        let args = [];
        for (var i=0; i < arguments.length; ++i) {
            if (i > 0) {
                args.push(arguments[i]);
            }
        }

        // Put your extension code here
        args.unshift(css);
        args.unshift('%c ' + name + ' ::');
        args.unshift(console);

        return Function.prototype.bind.apply(console.log, args);
    }
}

Debug.__proto__.customDebugCSS = 'background: #FF6600; color: #fff; font-size: 12px; padding:3px 20px 2px 5px;';
Debug.__proto__.displayDebugCSS = 'background: #44DD44; color: #666; font-size: 12px; padding:3px 20px 2px 5px;';
