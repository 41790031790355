module.exports={
  "id": "cache.json#",
  "properties": {
    "beforeRequest": {
      "oneOf": [
        { "type": "null" },
        { "$ref": "beforeRequest.json#" }
      ]
    },
    "afterRequest": {
      "oneOf": [
        { "type": "null" },
        { "$ref": "afterRequest.json#" }
      ]
    },
    "comment": {
      "type": "string"
    }
  }
}
