'use strict';

import $ from 'jquery';
import 'gsap';
let TweenMax = window.TweenMax;

import EventBus from './utils/EventBus';
import Debug from './utils/Debug';
import ObjectApi from './ObjectApi';
import ObjectDisplay from './ObjectDisplay';
import SideMenu from './SideMenu';
import Map from './Map';
import Attractor from './Attractor';
import TouchHint from './TouchHint';
import StillThereHint from './StillThereHint';
import Analytics from './Analytics';

/**
 * Entry point to the application
 *
 * @type {MainApp}
 */
window.MainAppClass = class MainApp {
    /**
     * Trigger creation of everything we need for the app to run
     */

    constructor(options) {
        Debug.log(this, 'initialised')();

        this.map = new Map();
        this.objectApi = new ObjectApi();
        this.objectDisplay = new ObjectDisplay(options.cmsUrl);
        this.sideMenu = new SideMenu();
        this.touchHint = new TouchHint();
        this.stillThere = new StillThereHint();
        this.analytics = new Analytics();

        if (! options.debug) {
            this.attractor = new Attractor();
        }
    }
}
