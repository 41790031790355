'use strict';

import ua from 'universal-analytics';

import EventBus from './utils/EventBus';

export default class Analytics {
    constructor() {
        this.visitor = ua('UA-80319239-1');

        this.bindEvents();
    }

    bindEvents() {
        // Map change
        EventBus.subscribe('sidemenu/change-map-layer', map => {
            let event = this.visitor.event('map-change', map);

            if (! window.debug) {
                event.send();
            }
        });

        // Marker select
        EventBus.subscribe('marker/select', object => {
            let event = this.visitor.event('_object-view', object.title);

            if (! window.debug) {
                event.send();
            }
        });
    }
}
