'use strict';

import $ from 'jquery';
import 'gsap';

import EventBus from './utils/EventBus';
import Debug from './utils/Debug';

/**
 * Object display tray
 */
export default class ObjectDisplay {

    constructor(cmsUrl) {
        Debug.log(this, "initialised")();

        this.cmsUrl = cmsUrl;

        this.$domEl = $(".object-display");
        this.$topBar = $(".bar.top", this.$domEl);
        this.$middleBar = $(".bar.middle", this.$domEl);
        this.$bottomBar = $(".bar.bottom", this.$domEl);

        this.$bars = $(".bar", this.$domEl);
        this.middleBarHeight = this.$middleBar.innerHeight();
        this.$overlay = $(".overlay");

        this.resetPosition();
        this.bindEvents();
    }

    bindEvents() {
        $("button.close", this.$bottomBar).on("mousedown", () => {
            this.hide();
            EventBus.publish('objectdisplay/close')
        });

        $(".picture", this.$middleBar).on("mousedown", this.showOverlay.bind(this));

        $("button.close", this.$overlay).on("mousedown", this.hideOverlay.bind(this));

        $(".logo-cover").on("click", e => e.preventDefault());

        EventBus.subscribe('marker/select', this.show.bind(this));

        EventBus.subscribe('marker/deselect', this.hide.bind(this));

        EventBus.subscribe('attractor/show', () => {
            this.hide();
            this.hideOverlay();
        });
    }

    resetPosition() {
        TweenMax.set(this.$domEl, {x:500, alpha:0});
        TweenMax.set(this.$middleBar, {height:0});
    }

    show(data) {
        this.populate(data);
        if (this.$domEl.hasClass("showing")) return;

        this.resetPosition();
        this.$domEl.addClass("showing");

        // pull out from side, then open up middle
        let tl = new TimelineMax();
        let height = this.middleBarHeight + (65 - this.$topBar.height());
        tl.to(this.$domEl, .2, {x:0, alpha:1, ease:Sine.easeOut});
        tl.to(this.$middleBar, .4, {height:height+"px", ease:Sine.easeOut});

        EventBus.publish('object-display/show-sidebar');
    }

    populate(data) {
        this.data = data;

        $(".title", this.$topBar).text(this.data.title);
        $(".date", this.$topBar).text(this.data.date);
        $(".credit", this.$middleBar).text(this.data.credit);
        $(".description", this.$middleBar).text(this.data.description);

        if (this.data.mediaType === 'picture') {
            $(".play", this.$middleBar).hide();

            if (this.data.media) {
                $(".picture", this.$middleBar).css("background-image", "url('" + this.cmsUrl + "/media/" + this.data.id + ".jpg')");
            } else {
                $(".picture", this.$middleBar).css("background-image", "url('/img/default.png')");
            }
        }
        else if (this.data.mediaType === 'video') {
            $(".play", this.$middleBar).show();
            $(".picture", this.$middleBar).css("background-image", "url('http://img.youtube.com/vi/" + this.data.media + "/hqdefault.jpg')");
        }

        // make middle bar shorter for longer titles
        let height = this.middleBarHeight + (65 - this.$topBar.height());
        this.$middleBar.css({ height: height + "px" });

        EventBus.publish('object-display/populate-sidebar');
    }

    hide() {
        // close middle, then push to side
        let tl = new TimelineMax({ onComplete:function(){
            this.$domEl.removeClass("showing");
        }.bind(this) });

        tl.to(this.$middleBar, .25, {height:"0px", ease:Sine.easeIn});
        tl.to(this.$domEl, .15, {x:500, alpha:0, ease:Sine.easeIn});

        EventBus.publish('object-display/hide-sidebar');
    }

    showOverlay() {
        if (this.data.mediaType === 'picture') {
            $(".object.video").hide();
            $(".object.picture").show();

            if (this.data.media) {
                $(".object.picture", this.$overlay).css("background-image", "url('" + this.cmsUrl + "/media/" + this.data.id + ".jpg')");
            } else {
                $(".object.picture", this.$overlay).css("background-image", "url('/img/default.png')");
            }
        }
        else if (this.data.mediaType === 'video') {
            $(".object.video").show();
            $(".object.picture").hide();
            $(".object.video").attr("src", "https://www.youtube.com/embed/" + this.data.media + "?rel=0&amp;controls=0&amp;autoplay=1&amp;showinfo=0&amp;fs=0");
        }

        $(".credit", this.$overlay).text(this.data.credit);
        TweenMax.to(this.$overlay, .2, {autoAlpha:1, ease:Sine.easeIn});

        EventBus.publish('object-display/show-overlay', { type: this.data.mediaType });
    }

    hideOverlay() {
        $(".object.video").attr("src", "");
        TweenMax.to(this.$overlay, .15, {autoAlpha:0, ease:Sine.easeIn});

        EventBus.publish('object-display/hide-overlay');
    }
}
